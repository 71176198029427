<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="industr-finance-details">
    <!-- 返回按钮 -->
    <div class="back-box">
      <img src="@/assets/industryFinance/back.png" alt="back" @click="back">
      <span @click="back">返回上一页</span>
    </div>
    <!-- 返回按钮结束 -->
    <!-- 详情内容开始 -->
    <div class="product-details-box">
      <!-- 右边固定在边上的东西 -->
      <div class="contact-information-box">
        <el-popover placement="left" trigger="hover">
          <div class="finance-center-qr-code-box">
            <img
              style="width: 146px"
              src="@/assets/industryFinance/official_accounts.png"
              alt="image"
            >
            <span>扫码关注公众号</span>
          </div>
          <div slot="reference" class="icon-box">
            <span class="image qr" />
            <span class="font">二维码</span>
          </div>
        </el-popover>
        <el-popover placement="left" trigger="hover">
          <div class="finance-center-contact-information">
            <img src="@/assets/industryFinance/phone.png" alt="phone">
            <div class="phone-box">
              <span>咨询电话</span>
              <p>400 199 9369</p>
            </div>
          </div>
          <div slot="reference" class="icon-box">
            <span class="image service" />
            <span class="font">客服</span>
          </div>
        </el-popover>
      </div>
      <!-- 左边 -->
      <div class="left">
        <!-- 上部分 -->
        <div class="upper-part">
          <!-- 产品名 -->
          <div class="name-box">
            <img
              class="product-logo"
              :src="picturePrefix + productDetails.productLogoUrl"
              alt=""
            >
            <span class="name elp-1">{{ productDetails.productName }}</span>
            <!-- <div class="page-view">
              <img src="@/assets/industryFinance/view.png" alt="icon">
              <span>浏览人数{{ productDetails.loanNumber }}人</span>
            </div> -->
          </div>
          <div class="credit-rate-box">
            <div class="credit-rate">
              <div class="item">
                <span>最高额度</span>
                <p>{{ productDetails.loanCredit || 0 }}万</p>
              </div>
              <div class="item">
                <span>贷款期限</span>
                <p>{{ productDetails.loanTerm || 0 }}个月</p>
              </div>
              <div class="item">
                <span>年化利率</span>
                <p
                  v-if="
                    productDetails.loanRateLower ===
                      productDetails.loanRateUpper
                  "
                  class="font"
                >
                  {{ productDetails.loanRateLower || 0 }}%
                </p>
                <p v-else class="font">
                  {{ productDetails.loanRateLower || 0 }}%-{{
                    productDetails.loanRateUpper || 0
                  }}%
                </p>
              </div>
            </div>
            <!-- 申请按钮 -->
            <div class="button-box">
              <button @click="applyImmediately">
                立即申请
              </button>
              <p>
                申请成功<span>{{ productDetails.loanNumber || 0 }}</span>人
              </p>
            </div>
          </div>
          <!-- 还款方式 -->
          <p class="repayment-mode">
            还款方式：<span>{{ productDetails.repaymentMode }}</span>
          </p>
        </div>
        <!-- 下部分 -->
        <div class="next-part">
          <h3 class="title">
            贷款详情
          </h3>
          <!-- 准入区域 -->
          <div class="interval-box">
            <h4 class="subtitle">
              准入区域
            </h4>
            <div class="access-area">
              <div class="item">
                <span class="label">全国准入区域</span>
                <p v-if="productDetails.nationMatchFlag === '01'">
                  {{ provinceArr.join("、") }}
                </p>
                <p v-else>
                  全国准入
                </p>
              </div>
              <div v-for="(item, index) in cityArr" :key="index" class="item">
                <span class="label">{{ item.name }}</span>
                <p class="name">
                  {{ item.city.join("、") }}
                </p>
              </div>
            </div>
          </div>
          <!-- 申请条件 -->
          <div class="interval-box">
            <h4 class="subtitle">
              申请条件
            </h4>
            <div
              class="introduction-content"
              v-html="productDetails.applyCondition"
            />
          </div>
          <!-- 征信要求 -->
          <div class="interval-box">
            <h4 class="subtitle">
              征信要求
            </h4>
            <div class="introduction-content">
              {{ productDetails.creditRequire }}
            </div>
          </div>
          <!-- 申请准备材料 -->
          <div class="interval-box">
            <h4 class="subtitle">
              申请准备材料
            </h4>
            <div class="introduction-content">
              {{ productDetails.applyData }}
            </div>
          </div>
          <!-- 操作指引 -->
          <div class="interval-box">
            <h4 class="subtitle">
              操作指引
            </h4>
            <div class="access-area">
              <div class="item">
                <span class="label">操作指引链接</span>
                <a
                  class="link"
                  :href="
                    productDetails.operationDocUrl ||
                      productDetails.operationVideoUrl
                  "
                  target="_blank"
                >
                  {{
                    productDetails.operationDocUrl ||
                      productDetails.operationVideoUrl
                  }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <img src="@/assets/industryFinance/screen.png" alt="screen">
        <span @click="productScreening" />
      </div>
    </div>
    <!-- 详情内容结束 -->
    <!-- 二维码弹窗 -->
    <div v-if="isShow" class="pop-up-box" @click.self="isShow = false">
      <div class="content-box">
        <img class="logo" src="@/assets/img/logo.png" alt="">
        <vue-qr class="code" :text="codeText" :size="184" />
        <p>请使用微信扫码进行申请</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductDesc } from '@/http/industryFinance'
import vueQr from 'vue-qr'
export default {
  components: { vueQr },
  data() {
    return {
      repayType: [],
      picturePrefix: '',
      productDetails: {},
      cityObj: {},
      cityArr: [],
      provinceArr: [],
      isShow: false,
      codeText: ''
    }
  },
  mounted() {
    if (this.$route.query.picturePrefix) {
      this.picturePrefix = this.$route.query.picturePrefix
    }
    if (this.$route.query.repayType) {
      this.repayType = JSON.parse(this.$route.query.repayType)
    }
    if (this.$route.query.id) {
      getProductDesc(this.$route.query.id, (res) => {
        this.productDetails = { ...res.data }
        // 获取城市
        if (
          this.productDetails.nationMatchFlag === '01' &&
          this.productDetails.productMatchCityList &&
          this.productDetails.productMatchCityList.length > 0
        ) {
          this.productDetails.productMatchCityList.forEach((item) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.cityObj.hasOwnProperty(item.provinceName)) {
              this.cityObj[item.provinceName].push(item.cityName)
            } else {
              this.cityObj[item.provinceName] = []
              this.cityObj[item.provinceName].push(item.cityName)
            }
            this.provinceArr.push(item.provinceName)
          })
          this.provinceArr = [...new Set(this.provinceArr)]
          for (const key in this.cityObj) {
            this.cityArr.push({
              name: key,
              city: this.cityObj[key]
            })
          }
        }
        // 获取还款方式
        if (
          this.productDetails.productRepayTypeList &&
          this.productDetails.productRepayTypeList.length > 0
        ) {
          this.productDetails.repaymentMode = ''
          this.productDetails.productRepayTypeList.forEach((val, index) => {
            const obj =
              this.repayType.find((item) => item.dictId === val.repayType) ||
              {}
            this.productDetails.repaymentMode +=
              (index !== 0 ? '、' : '') + obj.dictName
          })
        }
      })
    }
  },
  methods: {
    // 点击产品初筛按钮
    productScreening() {
      this.isShow = true
      let url = 'http://120.25.107.214/icfs-app'
      if (
        location.href.indexOf('369tecgroup') >= 0 ||
        location.href.indexOf('47.104.208.84') >= 0
      ) {
        url = 'https://icfs.369tecgroup.com'
      } else if (location.href.indexOf('120.77.45.206') >= 0) {
        url = 'http://120.77.45.206/icfs-app'
      }
      this.codeText = `${url}/#/pages/home/productScreening/index`
    },
    // 点击立即申请按钮
    applyImmediately() {
      this.isShow = true
      let url = 'http://120.25.107.214/icfs-app'
      if (
        location.href.indexOf('369tecgroup') >= 0 ||
        location.href.indexOf('47.104.208.84') >= 0
      ) {
        url = 'https://icfs.369tecgroup.com'
      } else if (location.href.indexOf('120.77.45.206') >= 0) {
        url = 'http://120.77.45.206/icfs-app'
      }
      this.codeText = `${url}/#/pages/home/product/productDetails?id=${this.$route.query.id}`
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.finance-center-contact-information {
  display: flex;
  align-items: center;
  .phone-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    p {
      color: $themeColors;
      font-size: 14px;
      font-weight: 500;
    }
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }
}
.finance-center-qr-code-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  .qr-code-img {
    display: block;
    height: 146px;
    width: 146px;
    margin-bottom: 8px;
  }
}
</style>
<style lang="scss" scoped>
.industr-finance-details {
  background-color: #f9f9f9;
  .pop-up-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    .content-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 248px;
      height: 313px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        color: #666666;
        font-size: 14px;
        font-weight: 500;
      }
      .code {
        height: 184px;
        width: 184px;
        margin: 16px 0 8px;
      }
      .logo {
        // width: 120px;
        height: 42px;
      }
    }
  }
  .contact-information-box {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    flex-direction: column;
    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 88px;
      width: 72px;
      background-color: #fff;
      border: 1px solid #ededed;
      border-right: 0;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      .image {
        display: inline-block;
        height: 40px;
        width: 40px;
      }
      .qr {
        background: url(../../../assets/industryFinance/qr.png);
        background-size: 100% 100%;
      }
      .service {
        background: url(../../../assets/industryFinance/service.png);
        background-size: 100% 100%;
      }
    }
    .icon-box:last-of-type {
      border-top: 0;
    }
    .icon-box:hover {
      background-color: $themeColors;
      border-color: $themeColors;
      color: #fff;
      .qr {
        background: url(../../../assets/industryFinance/qr_hover.png);
        background-size: 100% 100%;
      }
      .service {
        background: url(../../../assets/industryFinance/service_hover.png);
        background-size: 100% 100%;
      }
    }
  }
  .product-details-box {
    display: flex;
    margin: 0 auto 80px;
    width: $centerPlateWidth;
    .left {
      padding-right: 24px;
      flex: 1;
      .next-part {
        background-color: #fff;
        padding-bottom: 24px;
        border-radius: 4px;
        .interval-box {
          padding: 0 24px;
          .access-area {
            .item {
              display: flex;
              align-items: flex-start;
              color: #333333;
              font-size: 14px;
              font-weight: 500;
              margin-top: 8px;
              .label {
                flex: 0 0 150px;
                color: #666666;
                font-size: 14px;
                font-weight: 400;
              }
              .link {
                text-decoration: underline;
                color: #135fd7;
              }
            }
          }
          .introduction-content {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.6;
          }
          .subtitle {
            height: 20px;
            line-height: 20px;
            padding-left: 4px;
            border-left: 2px solid #d31e0e;
            margin: 32px 0 18px;
          }
        }
        .title {
          border-bottom: 1px solid #ededed;
          height: 68px;
          line-height: 68px;
          color: #000000;
          font-size: 20px;
          font-weight: 500;
          padding-left: 24px;
        }
      }
      .upper-part {
        background-color: #fff;
        margin-bottom: 20px;
        padding: 24px 32px 26px 24px;
        border-radius: 4px;
        .repayment-mode {
          color: #666666;
          font-size: 14px;
          font-weight: 400;
          span {
            color: #333333;
          }
        }
        .credit-rate-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0;
          .credit-rate {
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            .item {
              margin-right: 106px;
              p {
                margin-top: 8px;
                color: #000000;
                font-size: 24px;
                font-weight: 500;
              }
              .font {
                color: #d31e0e;
                font-weight: 900;
              }
            }
            .item:last-of-type {
              margin-right: 0;
            }
          }
          .button-box {
            position: relative;
            button {
              border: 0;
              outline: 0;
              background: #d31e0e;
              color: #fff;
              height: 38px;
              width: 147px;
            }
            p {
              position: absolute;
              top: 44px;
              left: 0;
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              width: 100%;
              text-align: center;
              span {
                color: #d31e0e;
              }
            }
          }
        }
        .name-box {
          padding-bottom: 16px;
          border-bottom: 1px solid #ededed;
          display: flex;
          align-items: center;
          .page-view {
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            img {
              height: 24px;
              width: 24px;
              margin-right: 4px;
            }
          }
          .name {
            flex: 1;
            padding: 0 16px;
            color: #000000;
            font-size: 20px;
            font-weight: 500;
          }
          .product-logo {
            height: 40px;
            flex: 0 0 40px;
            border-radius: 20px;
          }
        }
      }
    }
    .right-box {
      position: relative;
      width: 282px;
      height: 376px;
      span {
        position: absolute;
        left: 68px;
        bottom: 36px;
        width: 148px;
        height: 38px;
        display: inline-block;
        cursor: pointer;
        z-index: 9;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .back-box {
    height: 56px;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin: auto;
    width: $centerPlateWidth;
    cursor: pointer;
    img {
      height: 24px;
      width: 24px;
      margin-right: 4px;
    }
  }
}
</style>
